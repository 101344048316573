@import "~bootstrap/scss/bootstrap"
@import "./../../basedata/variables"

.vai
  background-color: #fff

.report-image
  max-width: 100px

.image-content
  display: inline-block
  width: 100px
  margin: 0 16px 16px 0

  img
    width: 100%
