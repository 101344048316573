@import "~bootstrap/scss/bootstrap"
@import "./../../basedata/variables"

.qr-wrapper
  margin: 16px 0

  img
    max-width: 200px
    margin-right: 16px

.qr-link
  display: block
  text-align: center
  color: $pm-primary
  +fontTitle
  padding: 16px 0
  font-size: 24px
  text-decoration: none
  border: 1px solid $pm-dark
  border-radius: 8px
  margin: 16px auto

  &:hover
    background-color: $pm-primary
    color: $white
