@import "~bootstrap/scss/bootstrap"
@import "./../../basedata/variables"

.game-tickets-header
    margin-bottom: 64px
    font-size: 14px

    span
        padding: 8px 16px
        color: $pm-dark

    .alert
        display: inline-block !important
        padding: 8px

.gate-name
    margin-top: -8px
    padding-left: 4px
    font-size: 12px
    background-color: $white

.game-label
    font-size: 14px
    padding: 0px 0px 8px 8px

    .gate-name
        display: inline-block
        padding-left: 4px
        font-size: 12px

    &.success
        background-color: $green-200

    &.danger
        background-color: $red-200
    &.warning
        background-color: $yellow-200

.card-title
    display: inline-block

.other-base
    border: 1px solid $pm-dark
    border-radius: 8px
    padding: 0px 8px
    display: inline-block
    width: 70px
    margin: 0 16px

    &.success
        background-color: $green-200

    &.danger
        background-color: $red-200
    &.warning
        background-color:  $red-200

.gate-card
    padding: 0 8px
