@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,400&display=swap')

@mixin fontSuper
  font-family: 'Roboto', 'Arial'
  font-weight: 900

@mixin fontTitle
  font-family: 'Roboto', 'Arial'
  font-weight: 700

@mixin fontSubTitle
  font-family: 'Roboto', 'Arial'
  font-weight: 500

@mixin fontDefault
  font-family: 'Roboto', 'Arial'
  font-weight: 400

// Palmeiras colors list
$pm-primary: #000000
$pm-dark: #000000
$white: #ffffff

// All colors list
$gray-100: #f8f9fa !default
$gray-200: #e9ecef !default
$gray-300: #dee2e6 !default
$gray-400: #ced4da !default
$gray-500: #adb5bd !default
$gray-600: #6c757d !default
$gray-700: #495057 !default
$gray-800: #343a40 !default
$gray-900: #212529 !default
$black: #000 !default
$blue: #0d6efd !default
$indigo: #6610f2 !default
$purple: #6f42c1 !default
$pink: #d63384 !default
$red: #dc3545 !default
$orange: #fd7e14 !default
$yellow: #ffc107 !default
$green: #198754 !default
$teal: #20c997 !default
$cyan: #0dcaf0 !default

$blue-100: tint-color($blue, 80%) !default
$blue-200: tint-color($blue, 60%) !default
$blue-300: tint-color($blue, 40%) !default
$blue-400: tint-color($blue, 20%) !default
$blue-500: $blue !default
$blue-600: shade-color($blue, 20%) !default
$blue-700: shade-color($blue, 40%) !default
$blue-800: shade-color($blue, 60%) !default
$blue-900: shade-color($blue, 80%) !default

$indigo-100: tint-color($indigo, 80%) !default
$indigo-200: tint-color($indigo, 60%) !default
$indigo-300: tint-color($indigo, 40%) !default
$indigo-400: tint-color($indigo, 20%) !default
$indigo-500: $indigo !default
$indigo-600: shade-color($indigo, 20%) !default
$indigo-700: shade-color($indigo, 40%) !default
$indigo-800: shade-color($indigo, 60%) !default
$indigo-900: shade-color($indigo, 80%) !default

$purple-100: tint-color($purple, 80%) !default
$purple-200: tint-color($purple, 60%) !default
$purple-300: tint-color($purple, 40%) !default
$purple-400: tint-color($purple, 20%) !default
$purple-500: $purple !default
$purple-600: shade-color($purple, 20%) !default
$purple-700: shade-color($purple, 40%) !default
$purple-800: shade-color($purple, 60%) !default
$purple-900: shade-color($purple, 80%) !default

$pink-100: tint-color($pink, 80%) !default
$pink-200: tint-color($pink, 60%) !default
$pink-300: tint-color($pink, 40%) !default
$pink-400: tint-color($pink, 20%) !default
$pink-500: $pink !default
$pink-600: shade-color($pink, 20%) !default
$pink-700: shade-color($pink, 40%) !default
$pink-800: shade-color($pink, 60%) !default
$pink-900: shade-color($pink, 80%) !default

$red-100: tint-color($red, 80%) !default
$red-200: tint-color($red, 60%) !default
$red-300: tint-color($red, 40%) !default
$red-400: tint-color($red, 20%) !default
$red-500: $red !default
$red-600: shade-color($red, 20%) !default
$red-700: shade-color($red, 40%) !default
$red-800: shade-color($red, 60%) !default
$red-900: shade-color($red, 80%) !default

$orange-100: tint-color($orange, 80%) !default
$orange-200: tint-color($orange, 60%) !default
$orange-300: tint-color($orange, 40%) !default
$orange-400: tint-color($orange, 20%) !default
$orange-500: $orange !default
$orange-600: shade-color($orange, 20%) !default
$orange-700: shade-color($orange, 40%) !default
$orange-800: shade-color($orange, 60%) !default
$orange-900: shade-color($orange, 80%) !default

$yellow-100: tint-color($yellow, 80%) !default
$yellow-200: tint-color($yellow, 60%) !default
$yellow-300: tint-color($yellow, 40%) !default
$yellow-400: tint-color($yellow, 20%) !default
$yellow-500: $yellow !default
$yellow-600: shade-color($yellow, 20%) !default
$yellow-700: shade-color($yellow, 40%) !default
$yellow-800: shade-color($yellow, 60%) !default
$yellow-900: shade-color($yellow, 80%) !default

$green-100: tint-color($green, 80%) !default
$green-200: tint-color($green, 60%) !default
$green-300: tint-color($green, 40%) !default
$green-400: tint-color($green, 20%) !default
$green-500: $green !default
$green-600: shade-color($green, 20%) !default
$green-700: shade-color($green, 40%) !default
$green-800: shade-color($green, 60%) !default
$green-900: shade-color($green, 80%) !default

$teal-100: tint-color($teal, 80%) !default
$teal-200: tint-color($teal, 60%) !default
$teal-300: tint-color($teal, 40%) !default
$teal-400: tint-color($teal, 20%) !default
$teal-500: $teal !default
$teal-600: shade-color($teal, 20%) !default
$teal-700: shade-color($teal, 40%) !default
$teal-800: shade-color($teal, 60%) !default
$teal-900: shade-color($teal, 80%) !default

$cyan-100: tint-color($cyan, 80%) !default
$cyan-200: tint-color($cyan, 60%) !default
$cyan-300: tint-color($cyan, 40%) !default
$cyan-400: tint-color($cyan, 20%) !default
$cyan-500: $cyan !default
$cyan-600: shade-color($cyan, 20%) !default
$cyan-700: shade-color($cyan, 40%) !default
$cyan-800: shade-color($cyan, 60%) !default
$cyan-900: shade-color($cyan, 80%) !default
